<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { required } from "vuelidate/lib/validators";
import { VMoney } from "v-money";

export default {
  locales: {
    en: {},
    pt: {
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
    },
    es: {
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
    },
  },
  components: { Layout },
  data() {
    return {
      loading: false,
      success: false,
      value: "",

      table: {
        body: null,
        loading: true,
        errored: false,
        empty: false,
      },
      deposits: null,

      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: " BRL",
        precision: 2,
      },
      alert: {
        type: "",
        message: "",
        params: [],
      },
    };
  },
  directives: { money: VMoney },
  validations: {
    value: { required },
  },
  methods: {
    createDeposit() {
      this.loading = true;
      this.$v.$touch();

      if (this.value) {
        api
          .post("wallet/deposits", {
            value: this.value,
          })
          .catch((error) => {
            if (error) {
              this.loading = false;
            }
          })
          .then((response) => {
            if (response.data.status == "success") {
              this.value = "0,00 BRL";
              this.$v.$reset();

              this.$router.push("/store/order/" + response.data.order.id);
            } else {
              this.alert.type = "alert-danger";
              this.alert.message = response.data.message;
              if (response.data.params) {
                this.alert.params = response.data.params;
              } else {
                this.alert.params = [];
              }

              this.$v.$reset();
            }

            this.loading = false;
          });
      }
    },
  },
  mounted() {
  },
};
</script>

<template>
  <Layout>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link tag="a" to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
        </router-link>
      </li>
      <li class="breadcrumb-item"><router-link tag="a" to="/wallet/deposits">Meu Depósitos</router-link></li>
      <li class="breadcrumb-item d-none d-sm-block">Novo Depósito</li>
    </ol>

    <div class="page-title-box d-md-flex justify-content-between mb-3">
      <div>
        <h3>Novo Depósito</h3>
        <p>Aqui você pode depositar fundos em sua conta.</p>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <b-form class="p-3" @submit.prevent="createDeposit">
          <div v-if="alert.message" :class="'alert ' + alert.type">
            {{ t(alert.message) }}
          </div>
          <b-form-group id="value" :label="t('Digite o valor que deseja depositar')" label-for="value">
            <b-form-input id="value" v-model="value" v-money="money" type="text" inputmode="decimal" :class="{ 'is-invalid': $v.value.$error }" size="lg"></b-form-input>
            <div v-if="$v.value.$error" class="invalid-feedback">
              <span v-if="!$v.value.required">{{
                t("Amount is required.")
                }}</span>
            </div>
            <p class="font-size-13 py-2">Depósito mínimo: 5.000,00 BRL</p>
          </b-form-group>
          <div class="mt-4">
            <b-button :disabled="loading == true || this.value === '0,00 BRL'" type="submit" variant="default">
              {{ t("Depositar") }}
              <b-spinner v-if="loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
            </b-button>
          </div>
        </b-form>
      </div>
    </div>
  </Layout>
</template>
